import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  // ssr: true,
  theme: {
    themes: {
      light: {
        primary: "#F48203", // colors.purple,
        secondary: "#252733", //colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
      dark: {
        primary: colors.blue.lighten3,
      },
    },
  },
});
