import { Module } from "vuex";

import {
  CartMembership,
  CartProduct,
  CartService,
  CartServicePackage,
  CartVoucher,
  Client,
  Discount,
} from "@/types";
import { api } from "@/util/axios";

type cartState = {
  cart: {
    products: CartProduct[];
    services: CartService[];
    memberships: CartMembership[];
    vouchers: CartVoucher[];
    servicePackages: CartServicePackage[];
  };
  client: Client | undefined;
  discounts: Discount[];
};

const getDicountedValue = (
  discounts: Discount[],
  orderData: cartState["cart"]
) => {
  for (let i = 0; i < discounts.length; i++) {
    const discount = discounts[i];

    let discountedValue = 0;

    if (orderData.products?.length) {
      for (let i = 0; i < orderData.products?.length; i++) {
        const product = orderData.products[i];

        if (
          discount.applyTo !== "all" &&
          discount.applyTo !== "products" &&
          !discount.productsApplied.includes(product.product._id as string)
        ) {
          continue;
        }

        if (discount.valueType === "percentage") {
          discountedValue += (product.sub_total * discount.value) / 100;
        } else {
          discountedValue += Math.min(product.sub_total, discount.value);
        }
      }
    }
    if (orderData.services?.length) {
      for (let i = 0; i < orderData.services?.length; i++) {
        const service = orderData.services[i];

        if (
          discount.applyTo !== "all" &&
          discount.applyTo !== "services" &&
          !discount.servicesApplied.includes(service.service)
        ) {
          continue;
        }

        if (discount.valueType === "percentage") {
          discountedValue += (service.sub_total * discount.value) / 100;
        } else {
          discountedValue += Math.min(service.sub_total, discount.value);
        }
      }
    }
    if (orderData.servicePackages?.length) {
      for (let i = 0; i < orderData.servicePackages?.length; i++) {
        const _package = orderData.servicePackages[i];

        if (
          discount.applyTo !== "all" &&
          discount.applyTo !== "service-packages" &&
          !discount.servicePackagesApplied.includes(_package.servicePackage)
        ) {
          continue;
        }

        if (discount.valueType === "percentage") {
          discountedValue += (_package.total * discount.value) / 100;
        } else {
          discountedValue += Math.min(_package.total, discount.value);
        }
      }
    }

    return discountedValue;
  }
};

const cart: Module<cartState, unknown> = {
  namespaced: true,
  state: () => ({
    cart: {
      products: [],
      services: [],
      memberships: [],
      vouchers: [],
      servicePackages: [],
    },
    client: undefined,
    discounts: [],
  }),
  getters: {
    products: (state) => state.cart.products,
    deposit: (state) => {
      let deposit = 0;

      state.cart.services.forEach((item) => {
        deposit += item.deposit || 0;
      });
      return deposit;
    },
    quantity: (state) => {
      let qty = 0;
      state.cart.products.forEach((item) => {
        qty += item.quantity;
      });

      state.cart.memberships.forEach((item) => {
        qty += item.quantity;
      });

      state.cart.services.forEach((item) => {
        qty += item.quantity;
      });

      state.cart.vouchers.forEach((item) => {
        qty += item.quantity;
      });

      state.cart.servicePackages.forEach((item) => {
        qty += item.quantity;
      });
      return qty;
    },
    services: (state) => state.cart.services,
    memberships: (state) => state.cart.memberships,
    vouchers: (state) => state.cart.vouchers,
    client: (state) => state.client,
    servicePackages: (state) => state.cart.servicePackages,
    getDiscount: (state) => getDicountedValue(state.discounts, state.cart),
    discounts: (state) => state.discounts,
    total: (state) => {
      let total = 0;
      state.cart.products.forEach((item) => {
        const _total = item.quantity * item.unitPrice;
        total += _total;
      });

      state.cart.memberships.forEach((item) => {
        const _total = item.quantity * item.unitPrice;
        total += _total;
      });

      state.cart.services.forEach((item) => {
        const _total = item.quantity * item.unitPrice;
        total += _total;
      });

      state.cart.vouchers.forEach((item) => {
        const _total = item.quantity * item.unitPrice;
        total += _total;
      });

      state.cart.servicePackages.forEach((item) => {
        const _total = item.quantity * item.unitPrice;
        total += _total;
      });
      return total;
    },
    totalWithDeposit: (state) => {
      let total = 0;
      state.cart.products.forEach((item) => {
        const _total = item.quantity * item.unitPrice;
        total += _total;
      });

      state.cart.memberships.forEach((item) => {
        const _total = item.quantity * item.unitPrice;
        total += _total;
      });

      state.cart.services.forEach((item) => {
        total += item.deposit || 0;
      });

      state.cart.vouchers.forEach((item) => {
        const _total = item.quantity * item.unitPrice;
        total += _total;
      });

      state.cart.servicePackages.forEach((item) => {
        const _total = item.quantity * item.unitPrice;
        total += _total;
      });
      return total;
    },
  },
  mutations: {
    ADD_INVENTORY_PRODUCT: (state, _products: CartProduct[]) => {
      _products.forEach((item) => {
        const { sku } = item;
        const current = state.cart.products.filter((p) => p.sku === sku);
        if (current.length > 0) {
          state.cart.products.map((cp) => {
            return cp.sku === sku ? (cp.quantity += 1) : cp;
          });
        } else {
          state.cart.products.push(item);
        }
      });
    },
    REMOVE_INVENTORY_PRODUCT: (state, _sku: string) => {
      state.cart.products = state.cart.products.filter(
        (item) => item.sku !== _sku
      );
    },
    UPDATE_PRODUCT_QTY: (state, _data: { sku: string; qty: number }) => {
      state.cart.products.map((item) => {
        return item.sku === _data.sku ? (item.quantity = _data.qty) : item;
      });
    },
    ADD_SERVICE: (state, _services: CartService[]) => {
      _services.forEach((item) => {
        const current = state.cart.services.filter((s) => s.id === item.id);
        if (current.length > 0) {
          state.cart.services.map((cs) =>
            cs.id === item.id ? (cs.quantity += 1) : cs
          );
        } else {
          state.cart.services.push(item);
        }
      });
    },
    SET_SERVICE_PACKAGES: (state, packages) => {
      state.cart.servicePackages = packages;
    },
    REMOVE_SERVICE: (state, _id: string) => {
      state.cart.services = state.cart.services.filter((s) => s.id !== _id);
    },
    UPDATE_SERVICE_UNITPRICE: (state, data: { _price: number; id: string }) => {
      state.cart.services.map((_service) => {
        return _service.id == data.id
          ? (_service.unitPrice = data._price)
          : _service;
      });
    },
    UPDATE_SERVICE_QTY: (state, data: { _qty: number; id: string }) => {
      state.cart.services.map((_service) => {
        return _service.id == data.id
          ? (_service.quantity = data._qty)
          : _service;
      });
    },
    SET_CLIENT: (state, _client: any) => {
      state.client = _client;
    },
    ADD_MEMBERSHIP: (state, _memberships: CartMembership[]) => {
      _memberships.forEach((item) => {
        const current = state.cart.memberships.filter((s) => s.id === item.id);
        if (current.length > 0) {
          state.cart.memberships.map((cs) =>
            cs.id === item.id ? (cs.quantity += 1) : cs
          );
        } else {
          state.cart.memberships.push(item);
        }
      });
    },
    UPDATE_MEMBERSHIP_QTY: (state, data: { _qty: number; id: string }) => {
      state.cart.memberships.map((_membership) => {
        return _membership.id == data.id
          ? (_membership.quantity = data._qty)
          : _membership;
      });
    },
    REMOVE_MEMBERSHIP: (state, _id: string) => {
      state.cart.memberships = state.cart.memberships.filter(
        (s) => s.id !== _id
      );
    },
    REMOVE_PACKAGE: (state, _id: string) => {
      state.cart.servicePackages = state.cart.servicePackages.filter(
        (s) => s.servicePackage !== _id
      );
    },
    REMOVE_VOUCHER: (state, _id: string) => {
      state.cart.vouchers = state.cart.vouchers.filter((s) => s.id !== _id);
    },
    ADD_VOUCHERS: (state, _vouchers: CartVoucher[]) => {
      _vouchers.forEach((item) => {
        const current = state.cart.vouchers.filter((s) => s.id === item.id);
        if (current.length > 0) {
          state.cart.vouchers.map((cs) =>
            cs.id === item.id ? (cs.quantity += 1) : cs
          );
        } else {
          state.cart.vouchers.push(item);
        }
      });
    },
    DELETE_CART: (state) => {
      state.cart.products = [];
      state.cart.services = [];
      state.cart.memberships = [];
      state.client = undefined;
    },
    SET_DISCOUNTS: (state, discounts) => {
      state.discounts = discounts;
    },
  },
  actions: {
    addProductToCart(context, products: CartProduct[]) {
      context.commit("ADD_INVENTORY_PRODUCT", products);
    },
    removeProductFromCart(context, _sku: string) {
      context.commit("REMOVE_INVENTORY_PRODUCT", _sku);
    },
    updateProductQty(context, _item: { sku: string; qty: number }) {
      if (_item.qty == 0) {
        context.commit("REMOVE_INVENTORY_PRODUCT", _item.sku);
      } else {
        context.commit("UPDATE_PRODUCT_QTY", _item);
      }
    },
    addServiceToCart(context, services: CartService[]) {
      context.commit("ADD_SERVICE", services);
    },
    addServicePackagesToCart(context, servicePackages: CartServicePackage[]) {
      context.commit("SET_SERVICE_PACKAGES", servicePackages);
    },
    removeServiceFromCart(context, _id: string) {
      context.commit("REMOVE_SERVICE", _id);
    },
    updateServiceUnitPrice(context, data: { _price: number; id: string }) {
      context.commit("UPDATE_SERVICE_UNITPRICE", data);
    },
    updateServiceQty(context, data: { _qty: number; id: string }) {
      context.commit("UPDATE_SERVICE_QTY", data);
    },
    addCartClient(context, client: any) {
      context.commit("SET_CLIENT", client);
    },
    addMembershipToCart(context, _items: CartMembership[]) {
      context.commit("ADD_MEMBERSHIP", _items);
    },
    removeMembershipFromCart(context, _id: string) {
      context.commit("REMOVE_MEMBERSHIP", _id);
    },
    removeVoucherFromCart(context, _id: string) {
      context.commit("REMOVE_VOUCHER", _id);
    },
    updateMembershipQty(context, data: { _qty: number; id: string }) {
      context.commit("UPDATE_MEMBERSHIP_QTY", data);
    },
    addVoucherToCart(context, _items: CartVoucher[]) {
      context.commit("ADD_VOUCHERS", _items);
    },
    deleteCart(context) {
      context.commit("DELETE_CART");
    },
    removeServicePackageFromCart(context, id) {
      context.commit("REMOVE_PACKAGE", id);
    },
    async fetchDiscounts(context, businessId) {
      return await api
        .get(`/v1/discount?businessId=${businessId}&limit=10000`)
        .then((response) => {
          context.commit("SET_DISCOUNTS", response.data.discountPage.docs);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default cart;
