






































import Vue from "vue";
import { getAuth, onAuthStateChanged } from "@firebase/auth";

import { getAuthToken, setAuth } from "../util/auth";
import { Business, Role } from "@/types";

export default Vue.extend<any, any, any, any>({
  name: "Full",
  components: {
    AppBar: () =>
      import(
        /* webpackChunkName: "app-bar" */
        "@/components/drawer/AppBar.vue"
      ),
    Drawer: () =>
      import(
        /* webpackChunkName: "drawer" */
        "@/components/drawer/Drawer.vue"
      ),
    Footer: () =>
      import(
        /* webpackChunkName: "footer" */
        "@/components/Footer.vue"
      ),
    ExpandedDrawer: () =>
      import(
        /* webpackChunkName: "expanded-drawer" */
        "@/components/drawer/ExpandedDrawer.vue"
      ),
  },
  created() {
    if (getAuthToken()) {
      this.$store.dispatch("getLoggedInUser").then((role) => {
        if (role) {
          const roleId = localStorage.getItem("roleId") || "";
          const bid = (
            role.roles.find((r: Role) => r._id === roleId)?.business as Business
          )._id;
          const params = `?userId=${role.user._id}&businessId=${bid}`;
          this.$store.dispatch("fetchPermissions", params);
        }
      });
      this.$store.dispatch("getUserCountry");
      return;
    }
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      user?.getIdToken().then((token) => {
        setAuth({ token }, false);
        this.$store.dispatch("getLoggedInUser").then((role) => {
          if (role) {
            const roleId = localStorage.getItem("roleId") || "";
            const bid = (
              role.roles.find((r: Role) => r._id === roleId)
                ?.business as Business
            )._id;
            const params = `?userId=${role.user._id}&businessId=${bid}`;
            this.$store.dispatch("fetchPermissions", params);
          }
        });
        this.$store.dispatch("getUserCountry");
      });
    });
  },
  computed: {
    expandedSidebar() {
      return this.$store.getters.expandedSidebar;
    },
    showProgressBar() {
      return this.$store.getters.loadingRequest;
    },
  },
  methods: {
    closeExpandedSidebar() {
      this.$store.dispatch("toggleMini", false);
      this.$store.dispatch("expandSidebar", false);
    },
  },
  beforeRouteLeave(to, from, next) {
    this.closeExpandedSidebar();
    next();
  },
});
