import axios from "axios";
import { getAuthToken, setAuth } from "./auth";
import jwtDecode from "jwt-decode";

const BASE_URL = process.env.VUE_APP_GOOGLE_SECURETOKEN_ENDPOINT;
const API_KEY = process.env.VUE_APP_GOOGLE_API_KEY;

export const getNewToken = async (): Promise<string> => {
  const refresh_token: string = localStorage.getItem("refresh_token") ?? "";

  if (!refresh_token) return "";
  const body = {
    grant_type: "refresh_token",
    refresh_token,
  };
  const url = BASE_URL + `?key=${API_KEY}`;

  try {
    const res = await axios.post(url, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return res.data.access_token;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.log("refresh token error: ", error?.response?.data ?? error);
    return "";
  }
};

export const refreshToken = async (): Promise<void> => {
  const token = getAuthToken();

  try {
    const decoded: any = jwtDecode(token);
    const now = Date.now() / 1000 + 15 * 60; // fifteen mins before expiry

    if (now > (decoded.exp as number)) {
      const newToken = await getNewToken();

      if (newToken) setAuth({ token: newToken }, false);
    }
  } catch (err) {
    console.log("decode error: ", err);
  }
};
