import store from "@/store";
import axios from "axios";
// import swal from "sweetalert2";

import { clearAuth, getAuthToken, isAuthenticated, setAuth } from "./auth";
import { refreshToken } from "./token";

const BASE_URL = process.env.VUE_APP_API_URL;

export const api = axios.create({
  baseURL: BASE_URL,
  timeout: 30000, // 30 seconds
  headers: {
    "app-id": "ke.co.agizo.vendor",
  },
});

api.interceptors.request.use(async (config) => {
  if (isAuthenticated()) {
    await refreshToken();
    config.headers["Authorization"] = `Bearer ${getAuthToken()}`;
    config.headers["business-id"] = localStorage.getItem("businessId");
  }
  if (localStorage.getItem("country-alpha3-code")) {
    config.headers["country-alpha3-code"] = localStorage.getItem(
      "country-alpha3-code"
    );
  }
  store.dispatch("setLoadingRequest", true);
  return config;
});

api.interceptors.response.use(
  (response) => {
    const token: string = response.headers["authorization"]?.split(" ")[1];

    if (token) setAuth({ token }, false);
    store.dispatch("setLoadingRequest", false);
    return response;
  },
  async (err) => {
    console.log("api error: ", err);
    if (err.response?.status === 401) clearAuth(`${location.pathname}`);
    store.dispatch("setLoadingRequest", false);
    return Promise.reject(err);
  }
);
