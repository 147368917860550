


































































import Vue from "vue";
export default Vue.extend<any, any, any, any>({
  name: "BoxedLayout",
  computed: {
    showProgressBar() {
      return this.$store.getters.loadingRequest;
    },
  },
});
