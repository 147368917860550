import { isAuthenticated, checkAuthorization } from "@/util/auth";
import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta?.noAuth) {
    next();
  } else {
    if (isAuthenticated()) {
      delete to.query.ssoToken;
      delete to.query.roleId;
      if (to.meta?.isAuthorized) next();
      else {
        const authorizedPath = await checkAuthorization();
        if (authorizedPath) next();
        else next(`/subscribe?redirectUrl=${to.path}`);
      }
    } else {
      const redirectUrl = to.fullPath;
      const ssoToken = to.query.ssoToken;
      const roleId = to.query.roleId;
      let query = {};
      query = { ...query, redirectUrl };

      if (ssoToken) query = { ...query, ssoToken };
      if (roleId) query = { ...query, roleId };
      next({ path: "/login", query: query });
    }
  }
});

export default router;
