import store from "@/store";
import { Plan, User, VendorPlan } from "@/types";
import moment from "moment";

// const routeMap = {
//   "/": "",
//   "/calendar": "Appointment Management",
//   "/staff": "Staff Management",
//   "/checkout": "",
//   "/client": "",
//   "/services": "Services",
//   "/products": "Inventory Management",
//   "/order/:orderId": "",
//   "/payments": "Payments",
//   "/settings": "",
//   "/forms": "Form builder",
//   "/shop": "Ahidi Shop",
//   "/voucher": "Loyalty and Reward  programs",
//   "/client/forms/:formId": "",
//   "/website-builder": "Website Builder",
//   "/login": "",
//   "/forgot-password": "",
//   "/select-business": "",
//   "/sign-up": "",
//   "/communications": "",
//   "/reports": "Analytics and Reports",
//   "/email-editor": "",
//   "/getting-started": "",
//   "/membership": "Membership Management *",
//   "/marketing": "Marketing *",
//   "*": "",
// };

// const addOnMap = {};

export const setAuth = (
  authData: {
    token?: string;
    url?: string;
    plan?: Plan;
  },
  redirect = true
): void => {
  try {
    if (authData.token) localStorage.setItem("token", authData.token);

    if (redirect) {
      const url = authData.url ? authData.url : "/";

      location.assign(url);
    }

    if (authData.plan)
      localStorage.setItem("plan", JSON.stringify(authData.plan));
  } catch (error) {
    console.log("error setting auth ", error);
  }
};

export const isAuthenticated = (): boolean => {
  const token = localStorage.getItem("token") || false;
  const s = !!token;

  return (
    s && typeof token === "string" && token !== "undefined" && token !== "null"
  );
};

export const checkAuthorization = async (): Promise<boolean> => {
  if (!isAuthenticated()) return false;
  const roleId = localStorage.getItem("roleId");

  if (!roleId) return false;

  const data = await store.dispatch("getLoggedInUser");
  const vendorPlan: VendorPlan = data?.roles.find((role) => role._id === roleId)
    ?.business.vendorPlan;

  localStorage.setItem("plan", JSON.stringify(vendorPlan));

  if (!vendorPlan) return false;

  const plan = vendorPlan.plan as Plan;

  if (plan.title === "Ahidi Flex") return true;

  if (!vendorPlan.lastPaymentDate) {
    return false;
  }

  const months = moment().diff(vendorPlan.lastPaymentDate, "months");
  return months <= 1;

  // const features = plan?.features ?? [];

  // const permitted =
  //   routeMap[path] === undefined ||
  //   routeMap[path] === "" ||
  //   features.includes(routeMap[path]);

  // //console.log("permitted: ", permitted);
};

export const getAuthUser = (): User => {
  return JSON.parse(`${localStorage.getItem("user")}`);
};

export const getAuthToken = (): string => {
  if (!isAuthenticated()) return "";

  return `${localStorage.getItem("token")}`;
};

export const clearAuth = (to?: string, redirect = true): void => {
  localStorage.clear();
  if (redirect) location.assign(`/login?redirectUrl=${to ? to : "/"}`);
};
