












































import Vue from "vue";
export default Vue.extend<any, any, any, any>({
  name: "Auth",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store
        .dispatch("logout", { to: undefined, redirect: false })
        .then(() => {
          next();
        });
    });
  },
  computed: {
    showProgressBar() {
      return this.$store.getters.loadingRequest;
    },
  },
});
