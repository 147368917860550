












































import Vue from "vue";
export default Vue.extend({
  name: "Landing",
  computed: {
    showProgressBar() {
      return this.$store.getters.loadingRequest;
    },
  },
});
