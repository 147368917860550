var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('router-view')],1),_c('notifications',{attrs:{"group":"alerts","position":"top right"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{
          notification: true,
          'notification-error': item.type === 'error',
          'notification-warn': item.type === 'warn',
          'notification-success': item.type === 'success',
        }},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('span',[_vm._v(_vm._s(item.title))])]),_c('div',{domProps:{"innerHTML":_vm._s(item.text)}})])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }