import { setAuth, clearAuth } from "@/util/auth";
import { EmployeePermission, Plan, Role, User } from "@/types";
import { api } from "@/util/axios";
import { Module } from "vuex";

type AuthState = {
  user: User | undefined;
  redirectUrl: string;
  userRoles: Role[];
  role: undefined | Role;
  permission: undefined | EmployeePermission;
};

const auth: Module<AuthState, unknown> = {
  state: {
    user: undefined,
    redirectUrl: "",
    userRoles: [],
    role: undefined,
    permission: undefined,
  },
  getters: {
    user: (state) => state.user,
    userRoles: (state) => state.userRoles,
    role: (state) => state.role,
    permission: (state) => state.permission,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_REDIRECT_URL(state, url) {
      state.redirectUrl = url;
    },
    SET_USER_ROLES(state, roles) {
      state.userRoles = roles;
    },
    SET_ROLE(state, role) {
      state.role = role;
    },
    SET_PERMISSION(state, permission) {
      state.permission = permission;
    },
  },
  actions: {
    login(context, { token, url }) {
      setAuth({ token: token, url: url || "/" });
    },
    async register(context, payload) {
      return await api
        .post("/v1/auth/register", payload)
        .then((response) => {
          context.dispatch("setToast", {
            title: "Success",
            type: "success",
            text: "Successfully registered!",
          });
          return response.data.user;
        })
        .catch((error) => {
          context.dispatch("setToast", {
            title: "Registration failed!",
            type: "error",
            text: error.response?.data?.error?.message,
          });
        });
    },
    setRedirectUrl(context, url) {
      context.commit("SET_REDIRECT_URL", url);
    },
    updateUserProfile(context, data) {
      api
        .put(`/v1/user/${context.state.user?._id}`, data)
        .then((response) => {
          context.commit("SET_USER", response.data.user);
          context.dispatch("setToast", {
            title: "Success",
            type: "success",
            text: "Profile updated",
          });
        })
        .catch((error) => {
          context.dispatch("setToast", {
            title: "Updating profile failed!",
            type: "error",
            text: error.response?.data?.error?.message,
          });
        });
    },
    logout(context, payload: { to: string; redirect: boolean }) {
      clearAuth(payload.to, payload.redirect);
    },
    async getLoggedInUser(context) {
      return await api
        .get<{ roles: Role[]; user: User }>(`/v1/auth`)
        .then((response) => {
          context.commit("SET_USER", response.data.user);
          context.commit("SET_USER_ROLES", response.data.roles);
          if (response.data.roles?.length) {
            let role = response.data.roles[0];
            const roleId = localStorage.getItem("roleId") || "";
            if (roleId)
              role = response.data.roles.find(
                (role) => role._id === roleId
              ) as Role;
            context.commit("SET_ROLE", role);
          }
          return response.data;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async getUserCountry(context) {
      return await api
        .get(`/v1/ip-data`)
        .then((response) => {
          const {
            data: {
              data: { country_code },
            },
          } = response;
          let code = "";
          if (country_code === "KE") code = "KEN";
          else if (country_code === "AU") code = "AUS";
          if (code) localStorage.setItem("country-alpha3-code", code);
          return response.data.data;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchPermissions(context, params = "") {
      return await api
        .get(`/v1/permission/employee${params}`)
        .then((response) => {
          context.commit("SET_PERMISSION", response.data.employeePermission);
          return response.data.employeePermission;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default auth;
