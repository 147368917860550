



















import Vue from "vue";
export default Vue.extend<any, any, any, any>({
  name: "Help",
  components: {
    AppBar: () =>
      import(
        /* webpackChunkName: "app-bar" */
        "@/components/drawer/AppBar.vue"
      ),
    Drawer: () =>
      import(/* webpackChunkName: "drawer" */ "@/components/drawer/Drawer.vue"),
  },
});
