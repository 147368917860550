

























import Vue from "vue";

export default Vue.extend<any, any, any, any>({
  name: "App",
  computed: {
    toast() {
      return this.$store.getters.toast;
    },
    showToast() {
      return this.$store.getters.toast.show;
    },
  },
  watch: {
    showToast() {
      if (this.showToast && this.toast.text !== "Session expired") {
        this.$notify({
          group: "alerts",
          title: this.toast.title,
          text: this.toast.text,
          type: this.toast.type,
          duration: this.toast.timeout,
        });
        this.$store.dispatch("hideToast");
      }
    },
  },
});
