import Vue from "vue";
import Notifications from "vue-notification";
import * as firebase from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Flutterwave from "flutterwave-vue-v3";
import Vue2Editor from "vue2-editor";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { numberWithCommas, readableDate } from "./util/filters";

import "@/assets/main.scss";

Vue.use(Notifications);
Vue.use(VueSweetalert2);
Vue.filter("readableDate", readableDate);
Vue.filter("numberWithCommas", numberWithCommas);
Vue.use(Flutterwave, {
  publicKey: "FLWPUBK_TEST-4d5bd3a6fb865671533ef2c401d9bad2-X",
});
Vue.use(Vue2Editor);

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGGIN_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

const messaging = getMessaging(app);

getToken(messaging, {
  vapidKey: process.env.VUE_APP_VAPID_KEY,
})
  .then((token) => {
    if (token) {
      // fetch()
      localStorage.setItem("fcm-token", token);
    } else {
      console.log("no token");
    }
  })
  .catch((err) => {
    console.log("err: ", err);
  });

Vue.config.productionTip = false;

(window as any).vm = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
